import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import axiosInstance, { S3_PATH } from "../../services/AxiosInstance";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { getUserDataLocalStorage } from "../../services/AuthService";
export default function Classifieds() {
  const [error, setError] = React.useState({ error: false, errorMessage: "" });
  const [classifiedData, setClassifiedData] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(null);

  React.useEffect(() => {
    const userData = getUserDataLocalStorage();
    axiosInstance
      .get(`classified/favourites/${userData.userId}`)
      .then((res) => {
        setError({
          error: res?.data?.hasError,
          errorMessage: "Failed to load data.",
        });
        setClassifiedData(res?.data?.data);
      });
    return () => {};
  }, []);

  async function putStatus(id, status) {
    axiosInstance
      .put(`update_classified_status/${id}`, {
        status: status,
      })
      .then((res) => {
        setClassifiedData(null);
        getData();
      });
  }

  async function getData() {
    const userData = getUserDataLocalStorage();
    axiosInstance
      .get(`classified/favourites/${userData.userId}`)
      .then((res) => {
        setError({
          error: res?.data?.hasError,
          errorMessage: "Failed to load data.",
        });
        setClassifiedData(res?.data?.data);
      });
  }

  function searchStringInArray(str, strArray) {
    const temp = [];
    for (var j = 0; j < strArray.length; j++) {
      if (strArray[j]?.title === null) continue;
      if (strArray[j]?.title.toLowerCase().match(str.toLowerCase()))
        temp.push(strArray[j]);
    }
    if (temp.length === 0) {
      return null;
    } else {
      return temp;
    }
  }

  console.log(classifiedData?.[0]);
  return (
    <Fragment>
      {classifiedData === null ? (
        <div
          style={{
            height: "60vh",
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>Classifieds: </h3>
              <input
                type="text"
                className="form-control input-default ml-3 mt-1"
                placeholder="Search classifieds"
                style={{ marginBottom: "5%" }}
                value={searchText}
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    setSearchResult(null);
                    setSearchText(null);
                    return;
                  }
                  setSearchText(e.target.value);
                  // if (searchText !== null) {
                  const res = searchStringInArray(
                    e.target.value,
                    classifiedData
                  );
                  setSearchResult(res);
                  // }
                }}
              />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>
                  <strong>ROLL NO.</strong>
                </th> */}
                  <th>
                    <strong>Title</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Created On</strong>
                  </th>
                  <th>
                    <strong>Localisation</strong>
                  </th>
                  <th>
                    <strong>Type of Price</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText !== null && searchResult === null ? (
                  <strong>No records found by {searchText}</strong>
                ) : (
                  <></>
                )}
                {searchText !== null &&
                  searchResult?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.title}</span>
                          </div>
                        </td>
                        <td>{row?.description} </td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        <td>{row?.localisation}</td>
                        <td>{row?.type_of_price}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {searchText === null &&
                  classifiedData?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.title}</span>
                          </div>
                        </td>
                        <td>{row?.description} </td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        <td>{row?.localisation}</td>
                        <td>{row?.type_of_price}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
}
