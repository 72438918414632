// export const BASEURL = `https://full-dragons-leave-103-31-179-218.loca.lt/api`;
const BASEURL = `https://api.le224.net/api/`;

export const ADD_REFERAL = `${BASEURL}/user/add-referral`;
export const GET_SETTING = `${BASEURL}/get-settings`;
export const UPDATE_SETTING = `${BASEURL}/update-settings`;
export const ADDED_CREDIT = `${BASEURL}/add-credits`;
export const GET_REFERAL_STATICS = `${BASEURL}/get-refer-statics`;
export const GET_SINGLE_STATICS = `${BASEURL}/get-single-statics`;
export const GET_PAYMENT_STATUS = `${BASEURL}/get-payment-status`;
export const UPDATE_PLAN = `${BASEURL}/paymentplan`;

export function headerToken(token) {
  const headers = {
    headers: {
        "x-access-token": token,
    }
  };
  return headers;
}
