import React, { useState, useEffect } from "react"
import { Table } from "react-bootstrap"
import moment from "moment"
import { Link } from "react-router-dom"
import axios from "axios"
import { GET_PAYMENT_STATUS, headerToken } from "../utils/apiEndPoints"
import { useSelector } from "react-redux"
import LoadingScreen from "../components/LoadingScreen/LoadingScreen"
import { BiSort } from "react-icons/bi"
import { FaSortNumericUp, FaSortNumericDownAlt } from "react-icons/fa"
const PaymentStatus = () => {
  const [searchText, setSearchText] = useState(null)
  const [userStatus, setUserStaus] = useState([])
  const [searchResult, setSearchResult] = useState(null)
  const { idToken } = useSelector((state) => state.auth.auth)
  const [loading, setLoading] = useState(false)
  const [sortBy, setSortBy] = useState(null)

  function searchStringInArray(str, strArray) {
    const temp = []
    for (var j = 0; j < strArray?.length; j++) {
      if (strArray[j]?.status === null) continue
      if (strArray[j]?.status.toLowerCase().match(str.toLowerCase()))
        temp.push(strArray[j])
    }
    if (temp.length === 0) {
      return null
    } else {
      return temp
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          GET_PAYMENT_STATUS,
          headerToken(idToken)
        )
        setUserStaus(data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (sortBy != null && !searchText) {
      if (sortBy == "ascPrice") {
        setUserStaus((prev) => prev.sort((a, b) => a.plan.price - b.plan.price))
      } else if (sortBy == "dsePrice") {
        setUserStaus((prev) => prev.sort((a, b) => b.plan.price - a.plan.price))
      } else if (sortBy == "dseCredit") {
        setUserStaus((prev) =>
          prev.sort((a, b) => a.plan.credits - b.plan.credits)
        )
      } else if (sortBy == "ascCredit") {
        setUserStaus((prev) =>
          prev.sort((a, b) => b.plan.credits - a.plan.credits)
        )
      }
    }
  }, [sortBy])

  return (
    <section>
      {loading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3>Check Payment Status</h3>
            <input
              type="text"
              className="form-control input-default ml-3 mt-1"
              placeholder="Search user..."
              style={{ marginBottom: "5%" }}
              value={searchText}
              onChange={(e) => {
                if (e.target.value.trim() === "") {
                  setSearchText(null)
                  setSearchResult(null)
                  return
                }
                setSearchText(e.target.value)
                const res = searchStringInArray(e.target.value, userStatus)
                setSearchResult(res)
              }}
            />
          </div>
          {/* Render Table */}
          <Table responsive>
            {/* Table Header Start */}
            <thead>
              <tr>
                <th>
                  <strong>User Name</strong>
                </th>
                <th>
                  <strong>Plan Name</strong>
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (sortBy == "ascPrice") {
                      setSortBy("dsePrice")
                    } else {
                      setSortBy("ascPrice")
                    }
                  }}
                >
                  <strong>
                    {sortBy == "ascPrice" ? (
                      <FaSortNumericUp />
                    ) : sortBy == "dsePrice" ? (
                      <FaSortNumericDownAlt />
                    ) : (
                      <BiSort />
                    )}
                    Price
                  </strong>
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (sortBy == "ascCredit") {
                      setSortBy("dseCredit")
                    } else {
                      setSortBy("ascCredit")
                    }
                  }}
                >
                  <strong>
                    {sortBy == "ascCredit" ? (
                      <FaSortNumericUp />
                    ) : sortBy == "dseCredit" ? (
                      <FaSortNumericDownAlt />
                    ) : (
                      <BiSort />
                    )}
                    Credit
                  </strong>
                </th>
                <th>
                  <strong>Payment Date</strong>
                </th>
                <th>
                  <strong>Status</strong>
                </th>
              </tr>
            </thead>
            {/* Table Header End */}
            {/* Table Body Start */}
            <tbody>
              {searchText !== null && searchResult === null ? (
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <strong>No records found</strong>
                </div>
              ) : (
                <></>
              )}
              {searchText !== null &&
                searchResult?.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="w-space-no">
                          {item?.user?.first_name}
                        </span>
                      </div>
                    </td>
                    <td>{item?.plan?.title}</td>
                    <td>{item?.plan?.price}</td>
                    <td>{item?.plan?.credits}</td>
                    <td>{moment(item.createdAt).format("do MMM YYYY")}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {item.status === "SUCCESS" ? (
                          <i className="fa fa-circle text-success mr-1"></i>
                        ) : (
                          <i className="fa fa-circle text-danger mr-1"></i>
                        )}
                        {item.status === "SUCCESS" ? "SUCCESS" : item.status}
                      </div>
                    </td>
                  </tr>
                ))}
              {searchText === null &&
                userStatus.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="w-space-no">
                          {item?.user?.first_name}
                        </span>
                      </div>
                    </td>
                    <td>{item?.plan?.title}</td>
                    <td>{item?.plan?.price}</td>
                    <td>{item?.plan?.credits}</td>
                    <td>{moment(item.createdAt).format("do MMM YYYY")}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {item.status === "SUCCESS" ? (
                          <i className="fa fa-circle text-success mr-1"></i>
                        ) : (
                          <i className="fa fa-circle text-danger mr-1"></i>
                        )}
                        {item.status === "SUCCESS" ? "SUCCESS" : item.status}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* Table Body End */}
          </Table>
        </>
      )}
    </section>
  )
}

export default PaymentStatus
