import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Modal, Table } from "react-bootstrap";
import axiosInstance, { S3_PATH } from "../../services/AxiosInstance";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { getUserDataLocalStorage } from "../../services/AuthService";
import swal from "sweetalert";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function BannerAds() {
  const [error, setError] = React.useState({ error: false, errorMessage: "" });
  const [bannerAdData, setBannerAdData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [adBannerModal, setAdBannerModal] = React.useState(false);
  const startDateRef = React.useRef(false);
  const expireDateRef = React.useRef(false);
  const [searchText, setSearchText] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(null);
  const [modalText, setModalText] = React.useState({
    adName: "",
    startDate: new Date(),
    expireDate: new Date(),
    price: "",
    status: "Active",
    file: null,
    url:""
  });

  React.useEffect(() => {
    axiosInstance.get(`banner_ads`).then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setBannerAdData(res?.data?.data);
    });
    return () => {};
  }, []);

  async function putStatus(id, status) {
    axiosInstance
      .put(`banner_ads/${id}`, {
        status: status,
      })
      .then((res) => {
        setBannerAdData(null);
        getData();
      });
  }

  async function getData() {
    axiosInstance.get(`banner_ads`).then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setBannerAdData(res?.data?.data);
    });
  }

  async function getPaymentById(catId) {
    const res = await axiosInstance.get(`banner_ads/${catId}`);
    // console.log(res?.data);
    return res?.data?.data;
  }

  function searchStringInArray(str, strArray) {
    const temp = [];
    for (var j = 0; j < strArray.length; j++) {
      if (strArray[j]?.ad_name === null) continue;
      if (strArray[j]?.ad_name.toLowerCase().match(str.toLowerCase()))
        temp.push(strArray[j]);
    }
    if (temp.length === 0) {
      return null;
    } else {
      return temp;
    }
  }

  console.log(bannerAdData?.[3]);
  return (
    <Fragment>
      <Modal className="fade" show={adBannerModal} onHide={setAdBannerModal}>
        <Modal.Header>
          <h4 className="text-black">Add a Banner Ad</h4>
          <Button
            variant=""
            className="close"
            onClick={() => setAdBannerModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          Ad Name:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter Plan Title"
            style={{ marginBottom: "5%" }}
            value={modalText.adName}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, adName: e.target.value };
              });
            }}
          />
          Url:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter url"
            style={{ marginBottom: "5%" }}
            value={modalText.url}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, url: e.target.value };
              });
            }}
          />
          Start Date:
          <div style={{ marginBottom: "5%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                label=""
                // clearable
                format="dd/MM/yyyy"
                // disableFuture
                value={modalText.startDate}
                onChange={(e) => {
                  startDateRef.current = true;
                  setModalText((prev) => {
                    return {
                      ...prev,
                      startDate: e,
                    };
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          Expire Date:
          <div style={{ marginBottom: "5%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                label=""
                // clearable
                format="dd/MM/yyyy"
                // disableFuture
                value={modalText.expireDate}
                onChange={(e) => {
                  expireDateRef.current = true;
                  setModalText((prev) => {
                    return {
                      ...prev,
                      expireDate: e,
                    };
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          Banner Price:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter Plan Price"
            style={{ marginBottom: "5%" }}
            value={modalText.price}
            onChange={(e) => {
              setModalText((prev) => {
                if (isNaN(Number(e.target.value))) {
                  return { ...prev };
                }
                return { ...prev, price: e.target.value };
              });
            }}
          />
          Status:
          <select
            style={{ marginBottom: "5%" }}
            className="form-control form-control-lg"
            value={modalText.status}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, status: e.target.value };
              });
            }}
          >
            <option style={{ color: "#000" }}>Active</option>
            <option style={{ color: "#000" }}>Pending</option>
          </select>
          Upload File:
          <br />
          <input
            type="file"
            // value={modalText.file}
            accept="image/*"
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, file: e.target.files[0] };
              });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setAdBannerModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (modalText.adName === "") {
                swal("Add Banner Ad Failed", "Please enter Ad Name", "error");
                return;
              }
              if (startDateRef.current === false) {
                swal(
                  "Add Banner Ad Failed",
                  "Please select a start date",
                  "error"
                );
                return;
              }
              if (expireDateRef.current === false) {
                swal(
                  "Add Banner Ad Failed",
                  "Please select an expire date",
                  "error"
                );
                return;
              }
              if (modalText.price === "") {
                swal("Add Banner Ad Failed", "Please enter a price", "error");
                return;
              }
              if (modalText.url === "") {
                swal("Add Banner Ad Failed", "Please enter a url", "error");
                return;
              }
              if (modalText.file === null) {
                swal("Add Banner Ad Failed", "Please select a file", "error");
                return;
              }
              const formData = new FormData();
              formData.append("ad_name", modalText.adName);
              formData.append("url", modalText.url);
              formData.append("start_date", modalText.startDate);
              formData.append("expire_date", modalText.expireDate);
              formData.append("status", modalText.status.toLowerCase());
              formData.append("banner_price", modalText.price);
              formData.append("banner_image", modalText.file);
              setIsLoading(true);
              setAdBannerModal(false);
              axiosInstance.post("banner_ads", formData).then((res) => {
                setIsLoading(false);
                setBannerAdData(null);
                getData();
                setAdBannerModal(false);
                setModalText({
                  adName: "",
                  startDate: new Date(),
                  expireDate: new Date(),
                  price: "",
                  url:"",
                  status: "Active",
                  file: null,
                });
              });
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {bannerAdData === null || isLoading ? (
        <div
          style={{
            height: "60vh",
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h3>Banner Ads</h3>
                <input
                  type="text"
                  className="form-control input-default ml-3 mt-1"
                  placeholder="Search ads"
                  style={{ marginBottom: "5%" }}
                  value={searchText}
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setSearchResult(null);
                      setSearchText(null);
                      return;
                    }
                    setSearchText(e.target.value);
                    // if (searchText !== null) {
                    const res = searchStringInArray(
                      e.target.value,
                      bannerAdData
                    );
                    setSearchResult(res);
                    // }
                  }}
                />
              </div>
              <Button
                style={{ padding: 10 }}
                onClick={() => setAdBannerModal(true)}
                variant="primary"
              >
                Add a Banner Ad
              </Button>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>
                  <strong>ROLL NO.</strong>
                </th> */}
                  <th>
                    <strong>Ad Name</strong>
                  </th>
                  <th>
                    <strong>View Count</strong>
                  </th>
                  <th>
                    <strong>Click Count</strong>
                  </th>
                  <th>
                    <strong>Url</strong>
                  </th>
                  <th>
                    <strong>Expire Date</strong>
                  </th>
                  <th>
                    <strong>Banner Price</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText !== null && searchResult === null ? (
                  <strong>No records found by {searchText}</strong>
                ) : (
                  <></>
                )}
                {searchText !== null &&
                  searchResult?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.ad_name}</span>
                          </div>
                        </td>
                        <td>
                          <p>{row?.viewCount || 0}</p>
                        </td>
                        <td>
                          <p>{row?.clickCount || 0}</p>
                        </td>
                        <td>
                          <a href={row?.url} className="text-blue font-w500" target="_blank">Url</a>
                        </td>
                        <td>
                          {moment(row?.expire_date).format("do MMM YYYY")}
                        </td>
                        {/* <td>{row?.localisation}</td> */}
                        <td>{row?.banner_price}</td>
                        {/* <td>
                        <Button
                          style={{ padding: 10 }}
                          onClick={() => {
                            setIsLoading(true);
                            getPaymentById(row?.id).then((res) => {
                              console.log(res);
                              setIsLoading(false);
                              let message = `description: ${
                                res?.description
                              }\nprice: ${res?.price}\nCreated on: ${moment(
                                res?.createdAt
                              ).format("do MMM YYYY")}\nStatus: ${
                                res?.status === "active" ? "Active" : "Pending"
                              }`;
                              swal(
                                "Plan: " + res?.title,
                                message,
                                res?.length === 0 ? "error" : "info"
                              );
                            });
                          }}
                          variant="primary"
                        >
                          View Plan Details
                        </Button>
                      </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {searchText === null &&
                  bannerAdData?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.ad_name}</span>
                          </div>
                        </td>
                        <td>
                          <p>{row?.viewCount || 0}</p>
                        </td>
                        <td>
                          <p>{row?.clickCount || 0}</p>
                        </td>
                        <td>
                          <a href={row?.url} className="text-blue font-w500" target="_blank">Url</a>
                        </td>
                        <td>
                          {moment(row?.expire_date).format("do MMM YYYY")}
                        </td>
                        {/* <td>{row?.localisation}</td> */}
                        <td>{row?.banner_price}</td>
                        {/* <td>
                        <Button
                          style={{ padding: 10 }}
                          onClick={() => {
                            setIsLoading(true);
                            getPaymentById(row?.id).then((res) => {
                              console.log(res);
                              setIsLoading(false);
                              let message = `description: ${
                                res?.description
                              }\nprice: ${res?.price}\nCreated on: ${moment(
                                res?.createdAt
                              ).format("do MMM YYYY")}\nStatus: ${
                                res?.status === "active" ? "Active" : "Pending"
                              }`;
                              swal(
                                "Plan: " + res?.title,
                                message,
                                res?.length === 0 ? "error" : "info"
                              );
                            });
                          }}
                          variant="primary"
                        >
                          View Plan Details
                        </Button>
                      </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
}
