import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import axiosInstance, { S3_PATH } from "../../services/AxiosInstance";
import avatar2 from "../../images/avatar/2.jpg";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
export default function Users() {
  const [error, setError] = React.useState({ error: false, errorMessage: "" });
  const [userData, setUserData] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(null);
  React.useEffect(() => {
    axiosInstance.get("users").then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setUserData(res?.data?.data);
    });
    return () => {};
  }, []);

  async function putStatus(id, status) {
    axiosInstance
      .put(`user/${id}`, {
        status: status,
      })
      .then((res) => {
        setUserData(null);
        getData();
      });
  }

  async function getData() {
    axiosInstance.get("users").then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setUserData(res?.data?.data);
    });
  }

  function searchStringInArray(str, strArray) {
    const temp = [];
    for (var j = 0; j < strArray.length; j++) {
      if (strArray[j]?.first_name === null) continue;
      if (strArray[j]?.first_name.toLowerCase().match(str.toLowerCase()))
        temp.push(strArray[j]);
    }
    if (temp.length === 0) {
      return null;
    } else {
      return temp;
    }
  }

  console.log(userData?.[0]);
  return (
    <Fragment>
      {userData === null ? (
        <div
          style={{
            height: "80vh",
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>Users: </h3>
              <input
                type="text"
                className="form-control input-default ml-3 mt-1"
                placeholder="Search users"
                style={{ marginBottom: "5%" }}
                value={searchText}
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    setSearchResult(null)
                    setSearchText(null)
                    return
                  }
                  setSearchText(e.target.value)
                  // if (searchText !== null) {
                  const res = searchStringInArray(e.target.value, userData)
                  setSearchResult(res)
                  // }
                }}
              />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>
                  <strong>ROLL NO.</strong>
                </th> */}
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>User Id</strong>
                  </th>
                  <th>
                    <strong>Email</strong>
                  </th>
                  <th>
                    <strong>Created On</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Phone</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText !== null && searchResult === null ? (
                  <strong>No records found by {searchText}</strong>
                ) : (
                  <></>
                )}
                {searchText !== null &&
                  searchResult?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={S3_PATH + row?.user_pic}
                              className="rounded-lg mr-2"
                              width="24"
                              alt=""
                            />{" "}
                            <span className="w-space-no">
                              {row?.first_name}
                            </span>
                          </div>
                        </td>
                        <td>{row?.id} </td>
                        <td>{row?.mail_address} </td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>{row?.telephone}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                {searchText === null &&
                  userData?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={S3_PATH + row?.user_pic}
                              className="rounded-lg mr-2"
                              width="24"
                              alt=""
                            />{" "}
                            <span className="w-space-no">
                              {row?.first_name}
                            </span>
                          </div>
                        </td>
                        <td>{row?.id} </td>

                        <td>{row?.mail_address} </td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>{row?.telephone}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  )
}
