import axios from "axios";
import { BASEURL } from "../jsx/utils/apiEndPoints";
import { store } from "../store/store";

const axiosInstance = axios.create({
//  baseURL: `http://localhost:8080/api`,
    // baseURL: BASEURL,
   baseURL: `https://api.le224.net/api/`,
})

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  // console.log(token);
  //   config.params = config.params || {};
  config.headers = config.headers || {};
  config.headers["x-access-token"] = token;
  return config;
});
export const S3_PATH = "https://s3.eu-west-2.wasabisys.com/le224/";
export default axiosInstance;
