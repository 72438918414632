import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import TextInput from "../components/Forms/Input/TextInput";
import {
  GET_SETTING,
  headerToken,
  UPDATE_SETTING,
} from "../utils/apiEndPoints";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Setting = () => {
  const [onSignup, setOnSignUp] = useState();
  const [onReferalCode, setOnReferalCode] = useState();
  const { idToken } = useSelector((state) => state.auth.auth);
  const [loading, setLoading] = useState(false);
  const [deductPost, setDeductPost] = useState(0);

  async function addedUserCredit(e) {
    e.preventDefault();
    try {
      const payload = {
        signupCredit: onSignup,
        referralCredit: onReferalCode,
        creditPerAdPost:deductPost
      };
      await axios.put(UPDATE_SETTING, payload, headerToken(idToken));
      setOnSignUp(onSignup);
      setOnReferalCode(onReferalCode);
      toast.success("Update Sucesfully");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      if (idToken) {
        try {
          setLoading(true);
          const { data } = await axios.get(GET_SETTING, headerToken(idToken));
          setOnSignUp(data?.settings?.signupCredit);
          setOnReferalCode(data?.settings?.referralCredit);
          setDeductPost(data?.settings?.creditPerAdPost);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <section>
      <ToastContainer />
      {loading ? (
        <div
          style={{
            height: "60vh",
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <div>
          <h3>Update Creadit Amount</h3>
          <Form onSubmit={addedUserCredit} className="mt-4">
            <TextInput
              label="On Sign Up"
              type="number"
              className="form-control input-default ml-3"
              placeholder="On Signup Up..."
              value={onSignup}
              onChange={(e) => setOnSignUp(e.target.value)}
              id="onsignup"
            />
            <TextInput
              label="On Referal Code"
              type="number"
              className="form-control input-default ml-3"
              placeholder="On Referal Code..."
              value={onReferalCode}
              onChange={(e) => setOnReferalCode(e.target.value)}
              id="onReferalCode"
            />
            <TextInput
              label="Deduct on post"
              type="number"
              className="form-control input-default ml-3"
              placeholder="Deduct on post..."
              value={deductPost}
              onChange={(e) => setDeductPost(e.target.value)}
              id="deductPost"
            />
            <Button style={{ padding: 10 }} variant="primary" type="submit">
              Update Creadit
            </Button>
          </Form>
        </div>
      )}
    </section>
  );
};

export default Setting;
