import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import {
  GET_REFERAL_STATICS,
  GET_SINGLE_STATICS,
  headerToken,
} from "../utils/apiEndPoints";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";
import { BiSort } from "react-icons/bi"
import { FaSortNumericUp, FaSortNumericDownAlt } from "react-icons/fa"

const ReferalCodeStatus = () => {
  const [searchText, setSearchText] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const { idToken } = useSelector((state) => state.auth.auth);
  const [referalCodeData, setReferalCodeData] = useState([]);
  const [singleData, setSingleData] = useState([]);
  const [sortBy, setSortBy] = useState(null)

  function countFunc(data) {
    let classifiedsCount = 0;
    for (let i = 0; i < data?.refers?.length; i++) {
      classifiedsCount += data?.refers[i]?.classifieds?.length;
    }

    return classifiedsCount
  }

   function countForDetails(data) {
     let classifiedsCount = 0
     for (let i = 0; i < data?.refers?.length; i++) {
       classifiedsCount += data?.refers[i]?.classifieds?.length
     }

     return classifiedsCount + data?.refers?.length
   }

  function searchStringInArray(str, strArray) {
    const temp = [];
    for (var j = 0; j < strArray?.length; j++) {
      if (strArray[j]?.first_name === null) continue;
      if (strArray[j]?.first_name.toLowerCase().match(str.toLowerCase()))
        temp.push(strArray[j]);
    }
    if (temp.length === 0) {
      return null;
    } else {
      return temp;
    }
  }

  async function getSingleData(id) {
    setOpenModal(!openModal);
    setLoading1(true);
    try {
      const { data } = await axios.get(`${GET_SINGLE_STATICS}/${id}`, {
        headers: {
          "x-access-token": idToken,
        },
      });
      setSingleData(data);
      setLoading1(false);
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (idToken) {
        try {
          setLoading(true);
          const { data } = await axios.get(
            GET_REFERAL_STATICS,
            headerToken(idToken)
          );
          setLoading(false);
          setReferalCodeData(data);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    })();
  }, []);


  useEffect(() => {
    if (sortBy != null && !searchText) {
      if (sortBy == "ascRefer") {
        setReferalCodeData((prev) => prev.sort((a, b) => a?.refers?.length - b?.refers?.length))
      } else if (sortBy == "dseRefer") {
        setReferalCodeData((prev) =>
          prev.sort((a, b) => b?.refers?.length - a?.refers?.length)
        )
      } else if (sortBy == "dseAd") {
        setReferalCodeData((prev) =>
          prev.sort((a, b) => countFunc(a) - countFunc(b))
        )
      } else if (sortBy == "ascAd") {
        setReferalCodeData((prev) => prev.sort((a, b) => countFunc(b) - countFunc(a)))
      }
    }
  }, [sortBy])

  return (
    <>
      <Modal
        className="fade"
        show={openModal}
        onHide={() => setOpenModal(!openModal)}
      >
        <Modal.Header>
          <h4 className="text-black">Referral users</h4>
          <Button
            variant=""
            className="close"
            onClick={() => setOpenModal(!openModal)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {loading1 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreen />
            </div>
          ) : (
            <Table responsive>
              {/* Table Header Start */}
              <thead>
                <tr>
                  <th>
                    <strong>User Name</strong>
                  </th>
                  <th>
                    <strong>User Email</strong>
                  </th>
                  <th>
                    <strong>Telephone</strong>
                  </th>
                  <th>
                    <strong>Total Classified Ads</strong>
                  </th>
                </tr>
              </thead>
              {/* Table Header End */}
              <tbody>
                {singleData &&
                  singleData?.map((item) =>
                    item?.refers?.length > 0 ? (
                      item?.refers?.map((item) => (
                        <tr key={item?.id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="w-space-no">
                                {item?.first_name}
                              </span>
                            </div>
                          </td>
                          <td>{item?.mail_address}</td>
                          <td>{item?.telephone}</td>
                          <td>{item?.classifieds?.length}</td>
                        </tr>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          position: "absolute",
                          top: "50%",
                          left: "35%",
                          marginTop: "38px",
                        }}
                      >
                        <strong>No records found</strong>
                      </div>
                    )
                  )}
              </tbody>
            </Table>
          )}
        </Modal.Body>
      </Modal>
      <section>
        {loading ? (
          <div
            style={{
              height: "60vh",
              width: "80vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingScreen />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>Check Referal Code Status</h3>
              <input
                type="text"
                className="form-control input-default ml-3 mt-1"
                placeholder="Search user..."
                style={{ marginBottom: "5%" }}
                value={searchText}
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    setSearchText(null)
                    setSearchResult(null)
                    return
                  }
                  setSearchText(e.target.value)
                  const res = searchStringInArray(
                    e.target.value,
                    referalCodeData
                  )
                  setSearchResult(res)
                }}
              />
            </div>
            {/* Render Table */}
            <Table responsive>
              {/* Table Header Start */}
              <thead>
                <tr>
                  <th>
                    <strong>User Name</strong>
                  </th>
                  <th>
                    <strong>User Id</strong>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (sortBy == "ascRefer") {
                        setSortBy("dseRefer")
                      } else {
                        setSortBy("ascRefer")
                      }
                    }}
                  >
                    <strong>
                      {sortBy == "ascRefer" ? (
                        <FaSortNumericUp />
                      ) : sortBy == "dseRefer" ? (
                        <FaSortNumericDownAlt />
                      ) : (
                        <BiSort />
                      )}
                      Total Referal User
                    </strong>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (sortBy == "ascAd") {
                        setSortBy("dseAd")
                      } else {
                        setSortBy("ascAd")
                      }
                    }}
                  >
                    <strong>
                      {sortBy == "ascAd" ? (
                        <FaSortNumericUp />
                      ) : sortBy == "dseAd" ? (
                        <FaSortNumericDownAlt />
                      ) : (
                        <BiSort />
                      )}
                      Total Classified Ads
                    </strong>
                  </th>
                </tr>
              </thead>
              {/* Table Header End */}
              {/* Table Body Start */}
              <tbody>
                {searchText !== null && searchResult === null ? (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    <strong>No records found</strong>
                  </div>
                ) : (
                  <></>
                )}
                {searchText !== null &&
                  searchResult?.map((item) => (
                    <tr key={item?.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">{item?.first_name}</span>
                        </div>
                      </td>
                      <td>{item?.id}</td>
                      <td>{item?.refers?.length}</td>
                      <td>{countForDetails(item)}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => getSingleData(item?.id)}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                {searchText === null &&
                  referalCodeData?.map((item) => (
                    <tr key={item?.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">{item?.first_name}</span>
                        </div>
                      </td>
                      <td>{item?.id}</td>
                      <td>{item?.refers?.length}</td>
                      <td>{countFunc(item)}</td>
                      {countForDetails(item) !== 0 && (
                        <td>
                          <Button
                            variant="primary"
                            onClick={() => getSingleData(item?.id)}
                          >
                            View Details
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
              {/* Table Body End */}
            </Table>
          </>
        )}
      </section>
    </>
  )
};

export default ReferalCodeStatus;
