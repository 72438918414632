import React from "react";

const TextInput = ({ label, id, ...props }) => {
  return (
    <div className="mb-4">
      <label htmlFor={id}>{label}</label>
      <input {...props} id={id} />
    </div>
  );
};

export default TextInput;

{
  /* <TextInput
            label="User Id"
            type="text"
            className="form-control input-default ml-3"
            placeholder="UserId..."
            style={{ marginBottom: "5%" }}
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            id="userId"
          /> */
}
