import { useState, useEffect } from "react";
import React from "react";
import { Button } from "react-bootstrap";
import TextInput from "../components/Forms/Input/TextInput";
import axios from "axios";
import { ADDED_CREDIT, headerToken } from "../utils/apiEndPoints";
import { useSelector } from "react-redux";

const AddCredits = () => {
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState();
  const [message, setMessage] = useState("");
  const { idToken } = useSelector((state) => state.auth.auth);

  async function addedUserCredit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        user: userId,
        credits,
      };
      await axios.put(ADDED_CREDIT, payload, headerToken(idToken));
      setLoading(false);
      setUserId("")
      setCredits("")
      setMessage("added successfully");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 3000);
    return () => clearTimeout(timer);  
  }, [message])

  return (
    <section>
      <div>
        <h3>Added User Credits</h3>
        <p style={{ color : "green", fontSize: 18}}>{message}</p>
        <form onSubmit={addedUserCredit} className="mt-4">
          <TextInput
            label="User Id"
            type="text"
            className="form-control input-default ml-3"
            placeholder="UserId..."
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            id="userId"
          />
          <TextInput
            label="Credit Amount"
            type="number"
            className="form-control input-default ml-3"
            placeholder="Credit Amount..."
            value={credits}
            onChange={(e) => setCredits(e.target.value)}
            id="creditNumber"
          />
          <Button
            disabled={loading}
            style={{ padding: 10 }}
            variant="primary"
            type="submit"
          >
            {loading ? "loading..." : "Add a User Credit"}
          </Button>
        </form>
      </div>
    </section>
  );
};

export default AddCredits;