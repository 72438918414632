import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Modal, Table } from "react-bootstrap";
import axiosInstance, { S3_PATH } from "../../services/AxiosInstance";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { getUserDataLocalStorage } from "../../services/AuthService";
import swal from "sweetalert";
import axios from "axios";
import { headerToken, UPDATE_PLAN } from "../utils/apiEndPoints";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Classifieds() {
  const [error, setError] = React.useState({ error: false, errorMessage: "" });
  const { idToken } = useSelector((state) => state.auth.auth)
  const [paymentPlanData, setPaymentPlanData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addPaymentModal, setAddPaymentModal] = React.useState(false);
  const [searchText, setSearchText] = React.useState(null);
  const [searchResult, setSearchResult] = React.useState(null);
  const [credits, setCredits] = React.useState();
  const [priority, setPriority] = React.useState();
  const [editData,setEditData] = useState(null);
  const [modalText, setModalText] = React.useState({
    title: "",
    description: "",
    price: "",
    status: "active",
  });

  useEffect(() => {
    fetchData();
    getData()
  }, [addPaymentModal]);

  function fetchData(){
    axiosInstance.get(`paymentplan`).then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setPaymentPlanData(res?.data?.data);
    });
  }

  async function putStatus(id, status) {
    axiosInstance
      .put(`paymentplan/${id}`, {
        status: status,
      })
      .then((res) => {
        setPaymentPlanData(null);
        getData();
      });
  }

  async function getData() {
    setIsLoading(true)
    axiosInstance.get(`paymentplan`).then((res) => {
      setError({
        error: res?.data?.hasError,
        errorMessage: "Failed to load data.",
      });
      setPaymentPlanData(res?.data?.data);
      setIsLoading(false)
    });
  }

  async function getPaymentById(catId) {
    const res = await axiosInstance.get(`paymentplan/${catId}`);
    // console.log(res?.data);
    return res?.data?.data;
  }

  function searchStringInArray(str, strArray) {
    const temp = [];
    for (var j = 0; j < strArray.length; j++) {
      if (strArray[j]?.title === null) continue;
      if (strArray[j]?.title?.toLowerCase().match(str?.toLowerCase()))
        temp.push(strArray[j]);
    }
    if (temp.length === 0) {
      return null;
    } else {
      return temp;
    }
  }

  async function updatePlan(){
    const payload = {
      title: modalText.title,
      description: modalText.description,
      credits,
      price: Number(modalText.price),
      priority: Number(priority),
      status: modalText.status?.toLowerCase() || "active",
    };
    try {
      axios.put(`${UPDATE_PLAN}/${editData?.id}`, payload,  headerToken(idToken));
      toast.success("Update Sucesfully")
      getData()
      setAddPaymentModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() =>{
    setModalText({...editData});
    setCredits(editData?.credits);
    setPriority(editData?.priority);
  }, [editData])


  return (
    <Fragment>
      <ToastContainer />
      <Modal
        className="fade"
        show={addPaymentModal}
        onHide={setAddPaymentModal}
      >
        <Modal.Header>
          <h4 className="text-black">{editData ? "Update A Payment Plan": "Add a Payment Plan"}</h4>
          <Button
            variant=""
            className="close"
            onClick={() => setAddPaymentModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          Title:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter Plan Title"
            style={{ marginBottom: "5%" }}
            value={modalText.title}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, title: e.target.value };
              });
            }}
          />
          Credits:
          <input
            type="number"
            className="form-control input-default "
            placeholder="Add credits"
            style={{ marginBottom: "5%" }}
            value={credits < 0 ? 0 : credits}
            onChange={(e) => {
              setCredits(e.target.value);
            }}
          />
          Priority:
          <input
            type="number"
            className="form-control input-default "
            placeholder="Priority"
            style={{ marginBottom: "5%" }}
            value={priority}
            onChange={(e) => {
              setPriority(e.target.value) <= 0 ? 0 : e.target.value;
            }}
          />
          Description:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter Plan Description"
            style={{ marginBottom: "5%" }}
            value={modalText.description}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, description: e.target.value };
              });
            }}
          />
          Price:
          <input
            type="text"
            className="form-control input-default "
            placeholder="Enter Plan Price"
            style={{ marginBottom: "5%" }}
            value={modalText.price}
            onChange={(e) => {
              setModalText((prev) => {
                if (isNaN(Number(e.target.value))) {
                  return { ...prev };
                }
                return { ...prev, price: e.target.value };
              });
            }}
          />
          Status:
          <select
            className="form-control form-control-lg"
            value={modalText.status}
            onChange={(e) => {
              setModalText((prev) => {
                return { ...prev, status: e.target.value };
              });
            }}
          >
            <option style={{ color: "#000" }}>Active</option>
            <option style={{ color: "#000" }}>Pending</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setAddPaymentModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          {editData ? <Button onClick={updatePlan} variant="primary">Update</Button> : <Button
            variant="primary"
            onClick={() => {
              if (modalText.title === "") {
                swal("Add Plan Failed", "Please enter title", "error");
                return;
              }
              if (modalText.description === "") {
                swal("Add Plan Failed", "Please enter a description", "error");
                return;
              }
              if (modalText.price === "") {
                swal("Add Plan Failed", "Please enter a price", "error");
                return;
              }

              const payload = {
                title: modalText.title,
                description: modalText.description,
                credits,
                price: Number(modalText.price),
                priority: Number(priority),
                status: modalText.status?.toLowerCase() || "active",
              };
              axiosInstance
                .post("paymentplan", payload)
                .then((res) => {
                  setPaymentPlanData(null);
                  getData();
                  setAddPaymentModal(false);
                  setModalText({
                    title: "",
                    description: "",
                    price: "",
                    status: "active",
                  });
                });
            }}
          >
            Save changes
          </Button>}
        </Modal.Footer>
      </Modal>
      {paymentPlanData === null || isLoading ? (
        <div
          style={{
            height: "60vh",
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h3>Payment Plans</h3>
                <input
                  type="text"
                  className="form-control input-default ml-3 mt-1"
                  placeholder="Search plans"
                  style={{ marginBottom: "5%" }}
                  value={searchText}
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setSearchResult(null);
                      setSearchText(null);
                      return;
                    }
                    setSearchText(e.target.value);
                    // if (searchText !== null) {
                    const res = searchStringInArray(
                      e.target.value,
                      paymentPlanData
                    );
                    setSearchResult(res);
                    // }
                  }}
                />
              </div>
              <Button
                style={{ padding: 10 }}
                onClick={() => setAddPaymentModal(true)}
                variant="primary"
              >
                Add A Payment Plan
              </Button>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>
                  <strong>ROLL NO.</strong>
                </th> */}
                  <th>
                    <strong>Title</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Priority</strong>
                  </th>
                  <th>
                    <strong>Created On</strong>
                  </th>
                  {/* <th>
                    <strong>Localisation</strong>
                  </th> */}
                  <th>
                    <strong>Price</strong>
                  </th>
                  <th>
                    <strong>Credits</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchText !== null && searchResult === null ? (
                  <strong>No records found by {searchText}</strong>
                ) : (
                  <></>
                )}
                {searchText !== null &&
                  searchResult?.map?.((row, index) => {
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.title}</span>
                          </div>
                        </td>
                        <td>{row?.description} </td>
                        <td>{row?.priority || 0}</td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        {/* <td>{row?.localisation}</td> */}
                        <td>{row?.price}</td>
                        <td>
                          {row?.credits}
                          {/* <Button
                            style={{ padding: 10 }}
                            onClick={() => {
                              setIsLoading(true);
                              getPaymentById(row?.id).then((res) => {
                                setIsLoading(false);
                                let message = `description: ${
                                  res?.description
                                }\nprice: ${res?.price}\nCreated on: ${moment(
                                  res?.createdAt
                                ).format("do MMM YYYY")}\nStatus: ${
                                  res?.status === "active"
                                    ? "Active"
                                    : "Pending"
                                }`;
                                swal(
                                  "Plan: " + res?.title,
                                  message,
                                  res?.length === 0 ? "error" : "info"
                                );
                              });
                            }}
                            variant="primary"
                          >
                            View Plan Details
                          </Button> */}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <Button
                            style={{ padding: 10 }}
                            onClick={() => {
                              setAddPaymentModal(true);
                              setEditData(row)
                            }}
                            variant="primary"
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                {searchText === null &&
                  paymentPlanData?.map?.((row, index) => {
                    // console.log(row)
                    if (row?.status === "archive") {
                      return <></>;
                    }
                    return (
                      <tr key={index}>
                        {/* <td>
                      <strong>{row?.social_id}</strong>
                    </td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">{row?.title}</span>
                          </div>
                        </td>
                        <td>{row?.description} </td>
                        <td>{row?.priority || 0}</td>
                        <td>{moment(row?.createdAt).format("do MMM YYYY")}</td>
                        {/* <td>{row?.localisation}</td> */}
                        <td>{row?.price}</td>
                        <td>
                          {row?.credits}
                          {/* <Button
                            style={{ padding: 10 }}
                            onClick={() => {
                              setIsLoading(true);
                              getPaymentById(row?.id).then((res) => {
                                setIsLoading(false);
                                let message = `description: ${
                                  res?.description
                                }\nprice: ${res?.price}\nCreated on: ${moment(
                                  res?.createdAt
                                ).format("do MMM YYYY")}\nStatus: ${
                                  res?.status === "active"
                                    ? "Active"
                                    : "Pending"
                                }`;
                                swal(
                                  "Plan: " + res?.title,
                                  message,
                                  res?.length === 0 ? "error" : "info"
                                );
                              });
                            }}
                            variant="primary"
                          >
                            View Plan Details
                          </Button> */}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row?.status === "active" ? (
                              <i className="fa fa-circle text-success mr-1"></i>
                            ) : (
                              <i className="fa fa-circle text-danger mr-1"></i>
                            )}
                            {row?.status === "active" ? "Active" : "Pending"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp mr-1"
                              onClick={() =>
                                row?.status === "active"
                                  ? putStatus(row?.id, "inactive")
                                  : putStatus(row?.id, "active")
                              }
                            >
                              <i
                                className={
                                  row?.status === "active"
                                    ? "fa fa-stop"
                                    : "fa fa-play"
                                }
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              //test
                              // onClick={() =>
                              //   row?.status === "archive"
                              //     ? putStatus(row?.id, "active")
                              //     : putStatus(row?.id, "archive")
                              // }
                              // over
                              onClick={() => putStatus(row?.id, "archive")}
                            >
                              <i
                                className="fa fa-archive"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <Button
                            style={{ padding: 10 }}
                            onClick={() => {
                              setAddPaymentModal(true);
                              setEditData(row)
                            }}
                            variant="primary"
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
}
